import { AdvertTranslation } from 'enums'

import { ILocale } from 'types/app'

export const adLangsCodes: Record<AdvertTranslation, ILocale[]> = {
  [AdvertTranslation.English]: ['en'],
  [AdvertTranslation.Lithuanian]: ['lt'],
  [AdvertTranslation.EnglishLithuanian]: ['en', 'lt']
}

export const bonuses = [
  'disabilities',
  'companyCar',
  'healthInsurance',
  'officePet',
  'employeeTransportation',
  'freeParking',
  'fuelCompensation',
  'officeLunch',
  'sportsClub'
] as const

export type BonusesNames = typeof bonuses[number]
