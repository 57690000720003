import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { useCallback, useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { AdvertVisibilityType, CandidateStatus } from 'enums'

import useSettingsStore, { ActionType } from 'store/useSettingsStore'
import { useCreateApplication } from 'hooks/useApplications'
import { useShortMatchInfo, useUpdateCandidateMatch } from 'hooks/useMatching'

import adUtils from 'utils/adUtil'
import { handleQueryError } from 'utils/queryErrorHandler'
import { isCandidateRole, isOrgRole } from 'utils/role.util'

import { IAdvertHeaderProps } from './types'

const useAdvertHeader = ({
  id,
  visibilityType,
  apply
}: Pick<IAdvertHeaderProps, 'id' | 'visibilityType' | 'apply'>) => {
  const router = useRouter()
  const { t } = useTranslation('actions')
  const { data: sessionData, status } = useSession()
  const { hasAction, setRedirectTo, setHasAction } = useSettingsStore(
    (store) => store
  )
  const { mutate: onApplyMatch, isLoading: isCreatingApplicationLoading } =
    useCreateApplication({
      onSuccess: () => {
        toast(t('toast.applicationWasSuccessful', { ns: 'actions' }))
      }
    })

  const candidateId = useMemo(() => {
    if (isCandidateRole(sessionData?.user.role)) {
      return sessionData?.user.userId
    }
  }, [sessionData?.user.role, sessionData?.user.userId])

  const jobIsActive = useMemo(
    () => visibilityType === AdvertVisibilityType.Active,
    [visibilityType]
  )

  const { data: match } = useShortMatchInfo({
    payload: {
      candidateId: candidateId ?? '',
      advertId: id ?? ''
    },
    options: {
      enabled:
        !!apply && isCandidateRole(sessionData?.user.role) && jobIsActive,
      onSuccess: (data) => {
        //Process applying after sign in page
        if (hasAction !== ActionType.Apply) {
          return
        }
        setHasAction()

        //if match not exist or rejected apply not needed, clear action store
        if (!data || adUtils.isRejected(data)) {
          toast(t('toast.applicationNoMatch', { ns: 'actions' }))
          return
        }

        //if match already applied
        if (data.applicationId) {
          toast(t('toast.applicationExists', { ns: 'actions' }))
          return
        }

        //auto apply
        onApplyMatch({
          candidateId: data.candidateId,
          advertId: data.advertId
        })
      },
      onError: handleQueryError
    }
  })

  const isRejected = useMemo(
    () => !!match && match.candidateStatus === CandidateStatus.Rejected,
    [match]
  )
  const isApplied = useMemo(() => !!match?.applicationId, [match])

  const {
    mutate: updateCandidateMatchStatus,
    isLoading: isLoadingUpdateCandidateStatus
  } = useUpdateCandidateMatch({})

  const isApplyAccessible = useCallback(() => {
    if (!sessionData) {
      setHasAction(ActionType.Apply)
      setRedirectTo(`/ad/${id}`)
      router.push('/sign-in')
      return false
    }

    if (isOrgRole(sessionData?.user.role)) {
      router.push('/org')
      return false
    }

    return true
  }, [id, router, sessionData, setHasAction, setRedirectTo])

  useEffect(() => {
    if (isOrgRole(sessionData?.user.role)) {
      setHasAction()
    }
  }, [hasAction, router, sessionData, setHasAction])

  const showApplyBtn = useMemo<{
    btn: boolean
    rejectBtn: boolean
  }>(() => {
    if (!jobIsActive) {
      return { btn: false, rejectBtn: false }
    }
    if (status === 'unauthenticated') {
      return { btn: true, rejectBtn: false }
    }

    if (match) {
      return { btn: true, rejectBtn: true }
    }

    return { btn: false, rejectBtn: false }
  }, [jobIsActive, match, status])

  const onApply = useCallback(() => {
    if (!isApplyAccessible()) {
      return
    }

    onApplyMatch({
      candidateId: candidateId ?? '',
      advertId: id ?? ''
    })
    return
  }, [id, candidateId, isApplyAccessible, onApplyMatch])

  const onUpdateMatchStatus = useCallback(
    (matchStatus: CandidateStatus) => () => {
      if (id && candidateId) {
        updateCandidateMatchStatus({
          candidateId: candidateId,
          advertId: id,
          advertStatus: matchStatus
        })
      }
    },
    [id, candidateId, updateCandidateMatchStatus]
  )

  return {
    locale: router.locale,
    showApplyBtn,
    isApplied,
    isRejected,
    isLoadingUpdateCandidateStatus,
    isCreatingApplicationLoading,
    t,
    onApply,
    onUpdateMatchStatus
  }
}

export default useAdvertHeader
