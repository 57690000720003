import Spinner from 'components/Spinner'

import useAdvertTitle from 'hooks/useAdvertTitle'

import {
  AdvertBulletsSection,
  AdvertDescription,
  AdvertFunctions,
  AdvertHead,
  AdvertHeader,
  AdvertLanguages,
  AdvertOrganisation,
  AdvertSkills
} from './components'
import { useAdvertTranslation } from './index'
import { IAdvertProps } from './types'

const Advert = ({
  ad,
  settings,
  apply,
  print,
  showVisibilityStatus,
  isPartLoading
}: IAdvertProps) => {
  const { lang } = useAdvertTranslation({
    ns: ['ad'],
    translation: ad.translation
  })

  const { title } = useAdvertTitle({
    ad,
    brandName: ad.organisation?.brandName,
    lang
  })

  return (
    <div className="border border-slate-100">
      <AdvertHead
        settings={settings}
        print={print}
        showVisibilityStatus={showVisibilityStatus}
        visibilityType={ad.visibilityType}
        {...ad.organisation}
      />
      <AdvertHeader
        apply={apply}
        print={print}
        visibilityType={ad.visibilityType}
        title={title}
        {...ad.organisation}
        id={ad.id}
      />
      {isPartLoading ? (
        <Spinner isVisible />
      ) : (
        <div className="bg-white px-5 pb-10 md:px-20">
          <AdvertBulletsSection {...ad} />
          <AdvertDescription {...ad} />
          <AdvertFunctions {...ad} />
          <AdvertLanguages {...ad} />
          <AdvertSkills {...ad} />
          <AdvertOrganisation
            {...ad.organisation}
            translation={ad.translation}
          />
        </div>
      )}
    </div>
  )
}

export default Advert
